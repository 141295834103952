import React, { HTMLAttributes } from 'react';
import logo from './logo-full.png'
interface IconProps extends HTMLAttributes<HTMLImageElement> {
	width?: number;
	height?: number;
	alt?: string;
}
export const Logo = ({  height = 28, width=120 }: IconProps) => {
	return (
	<img src={logo} width={width} height={height} alt="logo" />
	);
};
